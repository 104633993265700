<template>
  <b-card
    no-body
  >
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="pagination.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />

          </b-col>

          <!-- Search -->
          <b-col
            cols="18"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="showBusinessCardTypeForm('create')"
              >
                Add Card Type
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <vue-good-table
        mode="remote"
        :pagination-options="{
          enabled: true,
          perPageDropdown: [5, 10, 20, 50, 100],
          dropdownAllowAll: false,
          mode: 'pages',
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :is-loading="isLoading"
        :total-rows="totalRecords"
        :rows="businessCardsTypes"
        :columns="columnDefs"

        max-height="60vh"
        :fixed-header="true"
        styleClass="vgt-table condensed bordered vgt-business_card_type"
        compactMode
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-selected-rows-change="onSelectionChange"
      >

        <template
          slot="column-filter"
          slot-scope="props"
        >
          <span
            v-if="props.column.field === 'created_on'"
            class="mt-2"
          >
            <flat-pickr
              v-model="created_on_filter"
              :config="configPicker"
              class="vgt-input"
              placeholder="Filter Added On"
              @input="(value) => updateFilters(props.column.field, value)"
            />
          </span>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span 
            v-if="props.column.field === 'image'"
            class="vgt-column d-flex align-items-center"
          >
            <span>
              <img
                fluid
                :src="props.row.image || $defaultCardTypeImage"
                style="height: 10rem; width: 10rem;"
              >
            </span>
          </span>

          <!-- <span v-else-if="props.column.field === 'status'"> -->
          <div
            v-else-if="props.column.field === 'finishings'"
            class="vgt-column d-flex align-items-center"
          >
            <div class="finishing-container d-flex flex-wrap align-content-center">
              <div
                v-for="finishing in props.row.finishings"
                :key="finishing.key"
                class=""
              >
                <div
                  class="finishing-img"
                  :class="{'custom-overlay-danger border border-danger' : finishing.deactivated_at !== null}"
                >
                  <img
                    v-b-tooltip.hover.top="finishing.name"
                    class="finishing-img cursor-pointer"
                    fluid
                    :src="finishing.image || $defaultCardTypeFinishingImage"
                    width="150"
                    height="150"
                    @click="showBusinessCardsFinishingPopup('edit', finishing.id, props.row.id) "
                  >
                </div>
              </div>

              <b-button
                v-b-tooltip.hover.top="`Add new Finishing`"
                class="finishing-button-add"
                variant="primary"
                @click="showBusinessCardsFinishingPopup('create', null, props.row.id)"
              >+</b-button>
            </div>
          </div>

          <div
            v-else-if="props.column.field === 'deactivated_at'"
            class="vgt-column d-flex align-items-center"
          >
            <b-badge
              pill
              class="h-6 my-auto"
              :variant="props.row.deactivated_at === null ? 'success' : 'danger'"
            >
              {{ props.row.deactivated_at === null ? 'Active' : 'Deactivated' }}
            </b-badge>
          </div>

          <!-- Column: actions -->
          <div
            v-else-if="props.column.field === 'actions'"
            class="vgt-column d-flex align-items-center"
          >
            <div class="text-nowrap">
              <feather-icon
                v-b-tooltip.hover.topleft="`Edit / View card details`"
                icon="EditIcon"
                size="25"
                class="mx-1 text-primary cursor-pointer text-bolder"
                @click="showBusinessCardTypeForm('edit', props.row.id)"
              />
            </div>
          </div>

          <!-- Column: Common -->
          <div 
            v-else
            class="vgt-column d-flex align-items-center"
          >
            {{ props.formattedRow[props.column.field] }}
          </div>
        </template>
      </vue-good-table>
    </b-overlay>

    <b-modal
      v-model="popupBusinessCardTypeForm"
      centered
      hide-footer
      :title="`${$capitalizeString(formType)} a Business Card Type`"
      size="sm"
      style="min-height: 800px;"
    >
      <business-card-type-form
        :card-type-id="cardTypeId"
        :form-type="formType"
        @close-modal="popupBusinessCardTypeForm = !popupBusinessCardTypeForm"
        @refresh-record="getBusinessCardsTypes"
        @show-finishing-popup="showBusinessCardsFinishingPopup"
      />
    </b-modal>

    <b-modal
      v-model="popupBusinessCardsFinishingForm"
      centered
      hide-footer
      :title="`${$capitalizeString(finishingFormType)} a Business Card Type Finishing`"
      size="sm"
      style="min-height: 800px;"
    >
      <business-card-type-finishing-form
        :card-type-finishing-id="cardTypeFinishingId"
        :form-type="finishingFormType"
        :card-type-id="cardTypeId"
        @close-modal="popupBusinessCardsFinishingForm = !popupBusinessCardsFinishingForm"
        @refresh-record="getBusinessCardsTypes"
      />
    </b-modal>

  </b-card>
</template>

<script>
import { businessCardTypeFormMixins } from '../mixins/formMixins'

export default {
  mixins: [
    businessCardTypeFormMixins,
  ],
  data() {
    return {
      isLoading: false,
      businessCardsTypes: [],
      cardTypeFinishingId: null,

      tableColumns: [
        { key: 'image', label: 'Image', thStyle: { width: '10%' } },
        { key: 'name', label: 'Name', sortable: true, thStyle: { width: '15%' } },
        { key: 'description', label: 'Description', thStyle: { width: '30%' } },
        { key: 'Finishings', label: 'Finishings', thStyle: { width: '30%' } },
        { key: 'deactivated_at', label: 'Status', thStyle: { width: '15%' } },
        { key: 'actions', thStyle: { width: '10%' } },
      ],

      popupBusinessCardsFinishingForm: false,

      // pagination
      perPageOptions: [5, 10, 20, 40],
      pagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 5,
      },

      // vue-good-table
      columnDefs: [
        {
          label: 'Image',
          field: 'image',
          filterOptions: {
            trigger: 'enter',
            enabled: false,
            placeholder: '',
          },
          sortable: false,
          // pinned: true,
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Finishings',
          field: 'finishings',
          filterOptions: {
            trigger: 'enter',
            enabled: false,
            placeholder: '',
          },
          sortable: false,
        },
        {
          label: 'Status',
          field: 'deactivated_at',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',

            filterDropdownItems: [
              { value: 'active', text: 'Active' },
              { value: 'deactivated', text: 'Deactivated' },
            ],
          },
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          // cellRendererFramework: 'CellRendererActions',
          // pinned: true, // temporary fix for horizontal scrollbar on ag-grid
        },
      ],
      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 5,
      },
      selectedRows: null,
      totalRecords: 0,
    }
  },
  watch: {
    // server pagination
    'pagination.currentPage': function (val, oldVal) {
      this.getBusinessCardsTypes()
    },
    'pagination.perPage': function (val, oldVal) {
      this.getBusinessCardsTypes()
    },
  },
  created() {
    this.getBusinessCardsTypes()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      this.getBusinessCardsTypes()
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },
    onColumnFilter(params) {
      this.serverParams.page = 1
      this.updateParams(params)
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      currentFilters[column] = value
      this.updateParams({ columnFilters: currentFilters })
    },

    getBusinessCardsTypes() {
      this.isLoading = true

      let query = ''
      // query += `${this.$attachQuerySymbol(query)}pagination=${JSON.stringify(this.pagination)}`
      query = `?params=${JSON.stringify(this.serverParams)}`

      this.$http
        .get(`/api/business-cards-types${query}`)
        .then(response => {
          const { success, message, output, totalRecords } = response.data
          this.businessCardsTypes = output
          this.totalRecords = totalRecords

          this.isLoading = false
        })
        .catch(error => {
          this.$handleResponseError(error)
          this.isLoading = false
        })
    },

    showBusinessCardsFinishingPopup(formType, id = null, cardTypeId = null) {
      this.popupBusinessCardsFinishingForm = true

      this.cardTypeFinishingId = id
      this.finishingFormType = formType
      this.cardTypeId = cardTypeId
    },
  },
}
</script>

<style lang="scss" scoped>
.business-card-type-list-table {
  max-height: 60vh;
  min-height: 20vh;
}
.vgt-business_card_type {
  .vgt-column {
    height: 10rem;
  }
}

.finishing-container {
  overflow-y: auto;
  overflow-x: hidden;
  gap: 0.2rem 0.2rem;
  height: 10.4rem; // 3row * height of finishing-image * 2*gap-y
  width: 27rem;
  padding: 0;
}
.finishing-img, .finishing-button-add {
  border-radius: 12px;
  height: 5rem;
  width: 5rem;
  padding: 0;
}

</style>
