/* eslint-disable import/prefer-default-export */
import BusinessCardTypeForm from '../forms/index.vue'
import BusinessCardTypeFinishingForm from '../../business-cards-finishings/forms/index.vue'

export const businessCardTypeFormMixins = {
  components: {
    BusinessCardTypeForm,
    BusinessCardTypeFinishingForm,
  },
  data() {
    return {
      popupBusinessCardTypeForm: false,
      formType: 'create',
      finishingFormType: 'create',
      cardTypeId: null,
    }
  },
  methods: {
    showBusinessCardTypeForm(formType, id = null) {
      this.popupBusinessCardTypeForm = true
      this.formType = formType
      this.cardTypeId = id
    },
  }
}