<template>
  <div>
    <b-row>

      <b-col
        cols="12"
        class="d-flex flex-wrap align-items-center justify-content-center"
      >
        <img
          ref="refImageImg"
          class="cursor-pointer"
          fluid
          :src="businessCardType.image || $defaultCardTypeImage"
          width="300"
          height="300"
          data-placement="left"
          data-toggle="tooltip"
          title="Click here to upload an image"
          @click="$refs.uploadImageInput.click()"
        >
        <input
          ref="uploadImageInput"
          type="file"
          class="hidden"
          accept="image/*"
          @change="updateImage"
        >
      </b-col>

      <hr>

      <b-row class="w-100 m-0">
        <b-col
          cols="12"
          class=""
        >
          <b-row class="w-100 m-0 my-1 p-1">
            <b-col
              cols="12"
            >
              <form-label> Card Type Name </form-label>
              <b-form-input
                v-model="businessCardType.name"
                placeholder="Name of the type"
              />
            </b-col>

            <b-col
              cols="12"
            >
              <form-label> Description </form-label>
              <b-form-textarea
                v-model="businessCardType.description"
                placeholder="Insert brief description of the type"
              />
            </b-col>
          </b-row>
        </b-col>

        <!-- <b-col
          cols="6"
        >
          <div class="m-2 p-1 d-flex flex-wrap justify-content-start align-items-center">
            <h5 class="m-0 p-0 mb-1"> Finishings </h5>
            <div class="flex-break" />

            <div
              v-for="finishing in businessCardType.finishings"
              :key="finishing.key"
            >
              <img
                class="finishing-img cursor-pointer"
                fluid
                :src="defaultCardTypeImage"
                width="150"
                height="150"
                @click="showBusinessCardsFinishingPopup('edit', finishing.id, businessCardType.id) "
              >
            </div>

            <div>
              <b-button
                class="finishing-button-add"
                @click="showBusinessCardsFinishingPopup('create', null, businessCardType.id)"
              >+</b-button>
            </div>
          </div>
        </b-col> -->
      </b-row>

      <b-row
        class="w-100 mx-1 d-flex flex-wrap align-items-center justify-content-between"
      >
        <div>
          <b-button
            v-if="businessCardType.id"
            :variant="`outline-${$isEmpty(businessCardType.deactivated_at) ? 'danger' : 'success'}`"
            @click="toggleStatus"
          > {{ $isEmpty(businessCardType.deactivated_at) ? 'Deactivate' : 'Activate' }} </b-button>
        </div>
        <b-button
          class="ml-1"
          variant="primary"
          @click="submit"
        > {{ $capitalizeString(formType) }} </b-button>
      </b-row>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    cardTypeId: {
      type: [String, Number],
      default: null,
    },
    formType: {
      type: String,
      default: 'create',
    },
  },

  data() {
    return {
      businessCardType: {
        name: '',
        description: '',
        image: this.defaultCardTypeImage,
        finishings: [],
      },
      activeTab: 0,

      formData: new FormData(),
    }
  },
  created() {
    if (this.formType === 'edit') {
      this.getBusinessCardType(this.cardTypeId)
    }
  },
  methods: {
    getBusinessCardType(id) {
      this.$http
        .get(`/api/business-cards-types/${id}`)
        .then(response => {
          const { success, output } = response.data
          if (success) {
            this.businessCardType = output
          }
        })
        .catch(error => {
          this.$handleResponseError(error)
        })
    },
    submit() {
      // validate
      let validated = true
      let message = ''
      if (this.$isEmpty(this.businessCardType.name)) {
        message += 'Name'
        validated = false
      }
      message += ' is not valid'
      if (!validated) {
        this.$toastDanger('Unable To Submit', message)
        return
      }
      
      this.formData.append('form_data', JSON.stringify(this.businessCardType))

      const headers = {
        'Content-Type': 'multipart/form-data',
      }

      if (this.formType === 'create') {
        this.$http
          .post(
            `/api/business-cards-types`,
            this.formData,
            {
              headers,
            }
          )
          .then(response => {
            const { success, message, output } = response.data
            if (success) {
              this.businessCardType = output
              this.$toastSuccess('Operation Success', message)

              this.$emit('close-modal')
              this.$emit('refresh-record')
            }
          })
          .catch(error => {
            this.$handleResponseError(error)
          })
      } else if (this.formType === 'edit') {
        this.$http
          .put(
            `/api/business-cards-types/${this.cardTypeId}`,
            this.formData,
            {
              headers,
            },
          )
          .then(response => {
            const { success, message, output } = response.data
            if (success) {
              this.businessCardType = output
              this.$toastSuccess('Operation Success', message)
  
              this.$emit('close-modal')
              this.$emit('refresh-record')
            }
          })
          .catch(error => {
            this.$handleResponseError(error)
          })
      }
    },

    updateImage(input) {
      const file = input.target.files[0]
      this.previewImage(file, 'refImageImg')
      this.formData.append('image_file', file)
    },
    previewImage(file, imgRef) {
      const image = file
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e =>{
        this.$refs[imgRef].src = e.target.result
      };
    },
    showBusinessCardsFinishingPopup(formType, id, cardTypeId) {
      this.$emit('show-finishing-popup', formType, id, cardTypeId)
    },
    toggleStatus() {
      let status = 'activate'
      if (this.$isEmpty(this.businessCardType.deactivated_at)) {
        status = 'deactivate'
      }

      this.$http
        .put(`/api/business-cards-types/${this.businessCardType.id}/${status}`)
        .then(response => {
          this.$toastSuccess('Operation Success', 'Status updated')

          this.getBusinessCardType(this.cardTypeId)
          // this.$emit('close-modal')

          this.$emit('refresh-record')
        })
        .catch(error => {
          this.$handleResponseError(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.dynamic-link-input {
  // margin-top: 1em;
}

.finishing-img, .finishing-button-add {
  border-radius: 12px;
  height: 4em;
  width: 4em;
  margin: 0 0.5em;
  padding: 0;
}
</style>
